import React from "react";
import { 
  Seo, 
  Link, 
  Section, 
  Container, 
} from "@components";
import { PageLayout } from "../../components/site/layout/page-layout";
import { RightSidebar } from "../../components/site/product";

import { StaticImage } from "gatsby-plugin-image";

import "./custom_breakpoints.css";
const MhealthhMarketPage = ({ location }) => {
  return (
    <PageLayout pathname={location.pathname}>
      {/* Title Text start */}
      <div className="bg-[#122331] w-full">
        <Section>
          <Container className="py-[100px] md:py-[30px]">
            <div className="md:flex justify-between items-center">
              <div className="w-full md:w-[450px]">
                <h1 className="w-fit m-auto text-[40px] leading-[50px] md:ml-0 md:text-[53px] md:leading-[70px] font-semibold text-center text-[#ffffff] border-b-[6px] border-[#B21C7A] pb-[18px]">
                  Industries
                </h1>
              </div>
              <div className="w-full md:w-1/2 mt-[58px] md:mt-0">
                <StaticImage
                  src={`../../components/site/markets/images/industries.png`}
                  className="w-full md:max-w-[593px] h-auto"
                  alt="Mobile App and API Protection | Approov"
                  objectFit="contain"
                />
              </div>
            </div>
          </Container>
        </Section>
      </div>
      {/* Title Text end */}
      
      <Section>
        <Container className="pt-[20px] pb-[50px] md:pt-[60px] md:pb-[70px] md:flex justify-between">
          <div className="md:w-full md:mr-[38px]">
            <h6 className="text-[#bd2a7b] font-bold text-[30px] my-0">HEALTHCARE</h6>
            <div className="lg:flex justify-between lg:flex-row-reverse">
              <StaticImage
                src={`../../components/site/images/mHealth.png`}
                className="w-full lg:max-w-[350px] max-h-[365px] lg:max-h-fit h-auto rounded-[10px] mt-[20px] lg:mt-0"
                alt="mHealth concept; close up of a person holding a smartphone and medication while in video call with a medial professional"
                objectFit="cover"
              />
              <div className="w-full lg:mr-[35px]">
                <h2 className="text-[25px] lg:text-[31px] font-semibold text-[#1B3D57] my-0 mt-[40px] lg:mt-0">
                  Securing Mobile Healthcare Apps and their APIs
                </h2>
                <div className="lg:text-[17px] lg:leading-[29px] text-black">
                  Mobile healthcare applications and the APIs they access are at the heart of the new healthcare
                  ecosystem. Tablet and mobile apps are used by practitioners for all aspects of treatment and practice
                  management, and by patients to control and access healthcare data. Government regulations are driving
                  patient ownership of data while requiring secure interoperability. APIs must be protected against
                  unauthorized access to Personal Health Information (PHI) and ensure HIPAA compliance in this highly
                  regulated industry.
                </div>
              </div>
            </div>

            <div className="mt-[50px] md:mt-[40px] bg-[#f2f5f9] rounded-[10px] py-[30px] px-[20px] lg:py-[40px] lg:px-[50px]">
              <div className="mb-[25px]">
                <h2 className="text-[25px] lg:text-[31px] font-semibold text-[#1B3D57] text-left lg:text-center my-0">
                  Defend Sensitive Data and Protect your APIs from Attack
                </h2>
                <div className="bg-[#B21C7A] h-[4px] mt-[10px] w-[162px] lg:mt-[15px] lg:w-[230px] lg:mx-auto"></div>
                <div className="text-[#262727] text-[17px] mt-[10px] leading-[29px] font-medium lg:mt-[15px]">
                  Approov Mobile App Protection provides a multi-factor, end-to-end mobile API security solution
                  that complements identity management, endpoint, and device protection to lock-down proper API
                  usage. Only safe and approved apps can successfully use your APIs. Bots and fake or tampered
                  apps are all easily turned away and PHI is protected. Approov Provides Complete API Protection
                  for mHeath Apps and APIs including:
                </div>
              </div>
              <div className="w-full block flex-wrap lg:flex mt-[16px] md:mt-[50px]">
                <div className="lg:w-1/2 w-[100%] not-prose lg:pr-[15px] lg:border-r-2 border-[#e2e2e2]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#B21C7A] not-prose text-left lg:text-right">
                    Attacks on your APIs
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left lg:text-right not-prose pt-[5px]">
                        Bad actors use BOTS and automated scripts to attack your APIs directly, exposing patient data
                        using exploits such as <Link className="!underline" href="https://approov.io/blog/what-you-need-to-know-about-broken-object-level-authorization-bola">BOLA</Link>, and potentially degrading or overwhelming your back-end services.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pl-[15px] pt-[10px] lg:pt-0">
                  <p className="leading-[25px] text-[17px] leading-[29px] font-bold text-[#78A65A] not-prose text-left">
                    Approov Solution
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left not-prose pt-[5px]">
                        Approov ensures that traffic destined for your API is always coming from the legitimate mobile
                        app and not a third-party tool. This ensures synthetic traffic generated by account takeover
                        (ATO) tools and other API clients is blocked, protecting you from <Link className="!underline" href="https://approov.io/blog/the-rise-of-ddos">DDoS attacks</Link>. Traffic from
                        bots and automations are eliminated while no valid app traffic is rejected.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pr-[15px] pt-[10px] lg:pt-[20px] lg:border-r-2 border-[#e2e2e2]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#B21C7A] not-prose text-left lg:text-right">
                    Man-in-the-middle Attacks
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left lg:text-right not-prose pt-[5px]">
                        You can't depend on patients and healthcare professionals being on secure networks. If your
                        TLS is not implemented properly, third parties can steal secrets and manipulate your APIs.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pl-[15px] pt-[10px] lg:pt-[20px]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#78A65A] not-prose text-left">
                    Approov Solution
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left not-prose pt-[5px]">
                        Approov makes sure best-practices for TLS implementation are in place all the time, ensuring all
                        API calls are protected and <Link className="!underline" href="https://approov.io/mobile-app-security/rasp/dynamic-cert-pinning/">man-in-the-middle attacks</Link> are eliminated. Approov provides easy
                        administration of certificates and makes it easy to ensure pinning is implemented correctly,
                        eliminating the concern over apps being blocked when problems arise with a certificate.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pr-[15px] pt-[10px] lg:pt-[20px] lg:border-r-2 border-[#e2e2e2]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#B21C7A] not-prose text-left lg:text-right">
                    Compromised Environment
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left lg:text-right not-prose pt-[5px]">
                        Even if your app's authenticity checks out, it may still be running in a compromised
                        mobile client environment.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pl-[15px] pt-[10px] lg:pt-[20px]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#78A65A] not-prose text-left">
                    Approov Solution
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left not-prose pt-[5px]">
                        Approov detects rooted/jailbroken devices, apps running in debuggers or on emulators, or
                        malicious instrumentation frameworks manipulating your apps. You choose the security policy that
                        meets your needs. Security changes are rolled out over the air without requiring app updates.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pr-[15px] pt-[10px] lg:pt-[20px] lg:border-r-2 border-[#e2e2e2]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#B21C7A] not-prose text-left lg:text-right">
                  Stolen user credentials
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left lg:text-right not-prose pt-[5px]">
                        Bad actors perform credential stuffing attacks on your APIs.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pl-[15px] pt-[10px] lg:pt-[20px]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#78A65A] not-prose text-left">
                    Approov Solution
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left not-prose pt-[5px]">
                        Approov eliminates volumetric credential stuffing attacks on your APIs by restricting access
                        only to genuine instances of your app.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[50px] md:mt-[40px] lg:flex justify-between">
              <StaticImage
                src={`../../components/site/images/health_link.png`}
                className="w-full lg:max-w-[390px] h-[365px] h-auto rounded-[10px]"
                alt="message"
                objectFit="cover"
              />
              <div className="w-full lg:ml-[35px] pt-[20px]">
                <div className="lg:text-[17px] lg:leading-[29px] text-black">
                  Thirty mobile healthcare apps were tested. Every one displayed API vulnerabilities that exposed
                  personal healthcare data.
                </div>
                <Link href="/for/all-that-we-let-in" className="!no-underline">
                  <div className="text-white bg-[#78A65A] mt-[25px] px-[38px] py-[16px] rounded-[7px] font-medium text-[16px] leading-[28px] text-center md:w-fit transition hover:bg-[#1b7609]">
                    Read All That We Let In
                  </div>
                </Link>
                <Link href="/for/playing-with-fhir" className="!no-underline">
                  <div className="text-white bg-[#78A65A] mt-[25px] px-[38px] py-[16px] rounded-[7px] font-medium text-[16px] leading-[28px] text-center md:w-fit transition hover:bg-[#1b7609]">
                    Read Playing with FHIR
                  </div>
                </Link>
              </div>
            </div>

            <div className="mt-[50px] md:mt-[40px] bg-[#f2f5f9] rounded-[10px] py-[30px] px-[20px] lg:py-[40px] lg:px-[50px]">
              <div className="mb-[25px]">
                <h2 className="text-[25px] lg:text-[31px] font-semibold text-[#1B3D57] text-left lg:text-center my-0">
                  Ensure Compliance
                </h2>
                <div className="bg-[#B21C7A] h-[4px] mt-[10px] w-[162px] lg:mt-[15px] lg:w-[230px] lg:mx-auto"></div>
                <div className="text-[#262727] text-[17px] mt-[10px] leading-[29px] font-medium lg:mt-[15px]">
                  Approov adds additional security controls to the <Link className="!underline" href="https://approov.io/blog/guest-blog-alissa-knight-on-fhir-walker-authentication-and-authorization-in-fhir-apis">SMART/FHIR framework</Link> and makes it easy to
                  demonstrate HIPAA operational controls are in place to protect your APIs.
                </div>
              </div>
              <div className="w-full block flex-wrap lg:flex mt-[16px] md:mt-[50px]">
                <div className="lg:w-1/2 w-[100%] not-prose lg:pr-[15px] lg:border-r-2 border-[#e2e2e2]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#B21C7A] not-prose text-left lg:text-right">
                    Monitor and Report
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left lg:text-right not-prose pt-[5px]">
                        Demonstrate controls are in place and effective.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pl-[15px] pt-[10px] lg:pt-0">
                  <p className="leading-[25px] text-[17px] leading-[29px] font-bold text-[#78A65A] not-prose text-left">
                    Approov Solution
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left not-prose pt-[5px]">
                        App attestation traffic monitoring and security failure analytics are available for both
                        command-line and graphical analysis. Anonymized data provides information on the cause of the
                        security failures and information about the app, device, and network environments.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pr-[15px] pt-[10px] lg:pt-[20px] lg:border-r-2 border-[#e2e2e2]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#B21C7A] not-prose text-left lg:text-right">
                    Control your Security
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left lg:text-right not-prose pt-[5px]">
                        React to new threats and control policy.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pl-[15px] pt-[10px] lg:pt-[20px]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#78A65A] not-prose text-left">
                    Approov Solution
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left not-prose pt-[5px]">
                        Approov's security layers operate frictionlessly for your users. Secure over-the-air
                        capabilities update security policies, deliver enhancements, upgrade or rotate certificates,
                        blacklist specific devices, or deregister specific app versions.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pr-[15px] pt-[10px] lg:pt-[20px] lg:border-r-2 border-[#e2e2e2]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#B21C7A] not-prose text-left lg:text-right">
                    Easily Integrate and Operate
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left lg:text-right not-prose pt-[5px]">
                        Seamlessly integrate with other controls to create a unified solution.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pl-[15px] pt-[10px] lg:pt-[20px]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#78A65A] not-prose text-left">
                    Approov Solution
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left not-prose pt-[5px]">
                        Easy SDK integration on the frontend is combined with industry standard token checks on the
                        backend. Approov integrates easily and seamlessly with your Identity and Access Management (IAM)
                        solution. A wide range of existing mobile platforms and backend service integrations are
                        provided. A unified command line interface provides easy DevSecOps integration into your
                        existing developer and operations infrastructure.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-[50px] md:mt-0 md:max-w-[280px]">
            <RightSidebar />
          </div>
        </Container>
      </Section>
    </PageLayout>
  );
};

export default MhealthhMarketPage;
